import { useState, useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LinkedShipment from "../components/linkedShipment";
import PackageDetails from "../components/packageDetails";
import Form from "react-bootstrap/Form";
import { API } from "@aws-amplify/api";
import { AppContext } from "../libs/context";

export default function List({
  activePackages,
  showCheckBox,
  handleCheckboxChange,
  level,
}) {
  const [showGetPackageSpinner, setShowGetPackageSpinner] = useState(false);
  const [selectedPackageIndex, setSelectedPackageIndex] = useState(0);
  const [selectedPackageDetails, setSelectedPackageDetails] = useState({});
  const context = useContext(AppContext);

  function getActivePackage(index, currentEventKey, activeSince, pkgUUID) {
    console.log();
    let UUID;
    if (level !== 0) {
      UUID = context.UUID;
    } else {
      UUID = pkgUUID.replace("pkg_", "").replace("l_", "");
    }
    const queryString =
      parseInt(currentEventKey) === activeSince ? "?linked=true" : "";
    if (selectedPackageDetails.trackNo !== currentEventKey) {
      setShowGetPackageSpinner(true);
      setSelectedPackageIndex(index);
      API.get("cayco", `/${UUID}/${currentEventKey}${queryString}`).then(
        (res) => {
          if (res.linked) {
            API.post("cayco", `/${UUID}/pkgs`, { body: res.linked }).then(
              (pkgRes) => {
                res.linkedPkgDetails = pkgRes;
                setSelectedPackageDetails(res);
                setShowGetPackageSpinner(false);
              }
            );
          } else {
            setSelectedPackageDetails(res);
            setShowGetPackageSpinner(false);
          }
        }
      );
    }
  }

  return (
    <>
      <Accordion className="text-body">
        {activePackages.map((activePackage, index) => (
          <Card key={index}>
            <Card.Header>
              <Row>
                <Col xs={showCheckBox ? 11 : 12} sm={showCheckBox ? 11 : 12}>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    className="d-flex w-100 align-items-center text-decoration-none font-weight-bold "
                    eventKey={activePackage.trackNo}
                    value={activePackage.trackNo}
                    onClick={(e) =>
                      getActivePackage(
                        index,
                        activePackage.trackNo,
                        activePackage.activeSince,
                        activePackage.UUID
                      )
                    }
                  >
                    {parseInt(activePackage.trackNo) ===
                    activePackage.activeSince ? (
                      <span
                        style={{ textDecoration: "none" }}
                        className="font-weight-normal text-success"
                      >
                        Invoice:{" "}
                        <span
                          style={{ textDecoration: "underline" }}
                          className="font-weight-bold"
                        >
                          {activePackage.activeSince}
                        </span>
                      </span>
                    ) : (
                      <span
                        style={{
                          color: "#17a2b8",
                          textDecoration: "underline",
                        }}
                      >
                        {activePackage.trackNo}
                      </span>
                    )}

                    {index === selectedPackageIndex &&
                      showGetPackageSpinner === true && (
                        <Spinner
                          className="ml-2"
                          size="sm"
                          animation="border"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </Spinner>
                      )}
                    <div className="ml-auto">
                      <span className="text-success mr-2">{activePackage.parent ? "(Linked)" : ""}</span>
                      <span className="px-2 border bg-secondary rounded rounded-lg font-weight-bold text-warning">
                        In Transit
                      </span>
                    </div>
                  </Accordion.Toggle>
                </Col>
                {showCheckBox && (
                  <Col xs={1} sm={1} className="d-flex align-items-center">
                    <Form.Check
                      onClick={(e) => {
                        const selected = {
                          UUID: activePackage.UUID,
                          trackNo: activePackage.trackNo,
                        };
                        handleCheckboxChange(selected, e.target.checked);
                      }}
                      type="checkbox"
                    />
                  </Col>
                )}
              </Row>
            </Card.Header>
            <Accordion.Collapse eventKey={activePackage.trackNo}>
              <>
                <Card.Body className="p-1">
                  {Object.keys(selectedPackageDetails).length !== 0 &&
                    selectedPackageDetails.constructor === Object &&
                    !showGetPackageSpinner &&
                    selectedPackageDetails.activeSince ===
                      parseInt(selectedPackageDetails.trackNo) && (
                      <LinkedShipment
                        shipmentDetails={selectedPackageDetails}
                        setSelectedPackageDetails={setSelectedPackageDetails}
                      />
                    )}
                  {Object.keys(selectedPackageDetails).length !== 0 &&
                    selectedPackageDetails.constructor === Object &&
                    selectedPackageDetails.activeSince !==
                      parseInt(selectedPackageDetails.trackNo) &&
                    !showGetPackageSpinner && (
                      <PackageDetails
                        initPackageDetails={selectedPackageDetails}
                      />
                    )}
                </Card.Body>
              </>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </>
  );
}
