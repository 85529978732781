import { useState } from "react";
import uspsLogo from "../assets/logos/usps.png";
import upsLogo from "../assets/logos/ups.png";
import fedexLogo from "../assets/logos/fdx.png";
import amzLogo from "../assets/logos/amz.png";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { API } from "@aws-amplify/api";
import "../App.css";

import EditInvoice from "../components/editInvoice";
export default function PackageDetails({ initPackageDetails }) {
  const [packageDetails, setPackageDetails] = useState(initPackageDetails);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  return (
    <>
      <EditInvoice
        show={show}
        handleClose={handleClose}
        packageDetails={packageDetails}
        setPackageDetails={setPackageDetails}
      />
      <div className="bg-light border border-dark rounded-lg text-dark p-2 text-left">
        {packageDetails.info && (
          <div
            style={{ backgroundColor: "#ebeced" }}
            className="border d-flex align-items-center rounded-lg m-2 p-2"
          >
            <h4 className="mb-0 pb-0 mr-auto">
              <span className="mr-2">{packageDetails.trackNo}</span>
              {packageDetails.carrier === "USPS" && (
                <img height="35em" src={uspsLogo} alt="Logo" />
              )}
              {packageDetails.carrier === "UPS" && (
                <img height="25em" src={upsLogo} alt="Logo" />
              )}
              {packageDetails.carrier === "FedEx" && (
                <img height="25em" src={fedexLogo} alt="Logo" />
              )}
              {packageDetails.carrier === "Amazon" && (
                <img height="15em" src={amzLogo} alt="Logo" />
              )}
            </h4>
            <h4>Your package is on its way!</h4>
          </div>
        )}
        {packageDetails.invNo && (
          <h5 className="my-0 pt-0">
            <span>Invoice Number:</span>
            <Button
              className="font-weight-bold"
              size="lg"
              onClick={(e) => setShow(true)}
              variant="link"
            >
              {packageDetails.invNo}
            </Button>
          </h5>
        )}

        {packageDetails.info && (
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th>Quantity</th>
                <th>Item Name</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {packageDetails.info &&
                packageDetails.info.map((item, i) => (
                  <tr key={i}>
                    <td className="text-center">{item.Quantity}</td>
                    <td>{item.Name}</td>
                    <td>${item.Price.toFixed(2)}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        )}
        {packageDetails.discounts > 0 && (
          <h5>Discounts: -${packageDetails.discounts.toFixed(2)} USD</h5>
        )}
        {packageDetails.totalPreTax && (
          <h5>
            Total Dutiable Value: $
            {packageDetails.totalPreTax &&
              packageDetails.totalPreTax.toFixed(2)}{" "}
            USD
          </h5>
        )}
        <Row>
          <Col md="6">
            <h5>Shipping Estimate</h5>
            <span>
              Customs Duty (22% Estimate):&nbsp;$
              {(packageDetails.totalPreTax * 0.84 * 0.22).toFixed(2)} KYD
            </span>
            <br />
            <span>Port Authority Fee Estimate:&nbsp;$1.00 KYD</span>
            <br />
            <span>Container Inspection Fee Estimate:&nbsp;$1.00 KYD</span>
            <br />
            <span>Insurance Estimate:&nbsp;$1.00 KYD</span>
          </Col>
          <Col md="6">
            {(packageDetails.cuFt || packageDetails.weight) && (
              <h5>
                Dimension & Weight: {packageDetails.cuFt} CuFt;{" "}
                {packageDetails.weight} lbs
              </h5>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}
