import React from "react";
import { Route, Switch } from "react-router-dom";
import Tracking from "./pages/tracking";
import Admin from "./pages/admin";
import Login from "./pages/login";
import Signup from "./pages/signup";
import { AppContext } from "./libs/context";
export default function Routes() {
  return (
    <AppContext.Consumer>
      {(value) => (
        <Switch>
          {value.isAuthenticated === true && (
            <>
              <Route exact path="/">
                <Tracking />
              </Route>
              <Route exact path="/admin">
                <Admin />
              </Route>
            </>
          )}
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/signup">
            <Signup />
          </Route>
          <Route>
            <Login />
          </Route>
        </Switch>
      )}
    </AppContext.Consumer>
  );
}
