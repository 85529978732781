import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { Auth } from "aws-amplify";
import { useAppContext } from "../libs/context";
import { useFormFields } from "../libs/hooks";
import { useHistory } from "react-router-dom";

export default function Login() {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
  });
  const [showLoginSpinner, setShowLoginSpinner] = useState(false);
  const { userHasAuthenticated, setUUID } = useAppContext();
  const history = useHistory();

  function validateForm() {
    return fields.email.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    setShowLoginSpinner(true);
    try {
      await Auth.signIn(fields.email, fields.password);
      userHasAuthenticated(true);
      const id = (await Auth.currentAuthenticatedUser()).attributes[
        "custom:idp"
      ];
      setUUID(id);
      setShowLoginSpinner(false);
      history.push("/");
    } catch (e) {
      alert(e.message);
      setShowLoginSpinner(false);
    }
  }
  return (
    <div className="mt-2">
      <h4>Sign In</h4>
      <Form className="text-left" onSubmit={handleSubmit}>
        <Form.Group size="lg" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            autoFocus
            type="email"
            placeholder="Email"
            value={fields.email}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Form.Group size="lg" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            type="password"
            placeholder="Password"
            value={fields.password}
            onChange={handleFieldChange}
          />
        </Form.Group>
        <Button
          block
          size="lg"
          type="submit"
          className="d-flex align-items-center justify-content-center"
          disabled={!validateForm() || showLoginSpinner}
        >
          Login
          {showLoginSpinner === true && (
            <Spinner
              size="sm"
              className="ml-2"
              animation="border"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </Spinner>
          )}
        </Button>
      </Form>
    </div>
  );
}
