import { useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import FormControl from "react-bootstrap/FormControl";
import Spinner from "react-bootstrap/Spinner";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { API } from "@aws-amplify/api";
import { Storage } from "@aws-amplify/storage";
import { Document, Page, pdfjs } from "react-pdf";

import "../App.css";
export default function EditInvoice({
  handleClose,
  show,
  setPackageDetails,
  packageDetails,
}) {
  pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const pageRef = useRef();
  const deFixed = packageDetails.UUID.replace("pkg_", "").replace("l_", "");
  const [saveItemSpinnerShow, setSaveItemSpinnerShow] = useState(false);
  const [fileURL, setFileURL] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [addItem, setAddItem] = useState({});
  const [saveItemDisabled, setSaveItemDisabled] = useState(true);

  useEffect(() => {
    const typePrefix = packageDetails.UUID.split("u");
    const path = `${deFixed}/${typePrefix[0]}${packageDetails.trackNo}.pdf`;
    Storage.get(path).then((res) => {
      setFileURL(res);
    });
  }, [packageDetails.UUID, packageDetails.trackNo, deFixed]);

  async function addNewItem() {
    let queryString;
    if (packageDetails.UUID.startsWith("l_")) {
      queryString = "?linked=true";
    } else {
      queryString = "";
    }
    const addItemObj = {
      Name: addItem.Name,
      Price: parseFloat(addItem.Price),
      Quantity: parseInt(addItem.Quantity),
    };
    if (addItem.Code) {
      addItemObj.Code = parseInt(addItem.Code);
    }
    setSaveItemDisabled(true);
    setSaveItemSpinnerShow(true);
    API.patch(
      "cayco",
      `/${packageDetails.UUID.replace("pkg_", "").replace("l_", "")}/${
        packageDetails.trackNo
      }${queryString}`,
      {
        body: {
          addItemObj,
        },
      }
    ).then((res) => {
      setAddItem({});
      setPackageDetails({ ...packageDetails, info: res.info });
      setSaveItemSpinnerShow(false);
    });
  }

  async function deleteItem(i) {
    let queryString;
    if (packageDetails.UUID.startsWith("l_")) {
      queryString = "?linked=true";
    } else {
      queryString = "";
    }
    API.patch(
      "cayco",
      `/${packageDetails.UUID.replace("pkg_", "").replace("l_", "")}/${
        packageDetails.trackNo
      }${queryString}`,
      {
        body: {
          deleteItem: i,
        },
      }
    ).then((res) => {
      let modArr = packageDetails.info;
      modArr.splice(i, 1);
      setPackageDetails({ ...packageDetails, info: modArr });
    });
  }

  async function updateCodes(i) {
    let queryString;
    if (packageDetails.UUID.startsWith("l_")) {
      queryString = "?linked=true";
    } else {
      queryString = "";
    }
    const originalItemObj = packageDetails.info[i];
    const updatedItemObj = { ...originalItemObj };
    delete updatedItemObj.hasChanged;
    delete updatedItemObj.showSave;
    delete updatedItemObj.submitted;
    API.patch(
      "cayco",
      `/${packageDetails.UUID.replace("pkg_", "").replace("l_", "")}/${
        packageDetails.trackNo
      }${queryString}`,
      {
        body: {
          info: updatedItemObj,
          i: i,
        },
      }
    ).then((res) => {
      const info = [...packageDetails.info];
      info[i] = res.info[0];
      setPackageDetails({ ...packageDetails, info: info });
    });
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function removeTextLayerOffset() {
    const textLayers = document.querySelectorAll(
      ".react-pdf__Page__textContent"
    );
    textLayers.forEach((layer) => {
      const { style } = layer;
      style.top = "0";
      style.left = "0";
      style.transform = "";
    });
  }

  function getClick(e, page) {
    const rect = pageRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const xRatio = x / rect.width;
    const yRatio = y / rect.height;
    API.post("cayco", `/${deFixed}/text`, {
      body: {
        UUID: packageDetails.UUID,
        trackNo: packageDetails.trackNo,
        x: xRatio,
        y: yRatio,
        p: page,
      },
    })
      .then((res) => {
        setAddItem({
          ...addItem,
          Name: res,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }
  /* {showEdit ? (
              <InputGroup className="ml-2 w-50">
                <Form.Control
                  className="rounded rounded-lg"
                  defaultValue={packageDetails.invNo}
                  onChange={(e) => setChangeInvNo(e.target.value)}
                ></Form.Control>
                <InputGroup.Append>
                  <Button
                    onClick={() => console.log(changeInvNo)}
                    variant="success"
                  >
                    ✔
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            ) : (
              <Button
                className="font-weight-bold"
                size="lg"
                onClick={(e) => setShow(true)}
                variant="link"
              >
                {packageDetails.invNo}
              </Button>
            )}*/

            /* function modifyInvNo(invNo) {
    let queryString;
    if (packageDetails.UUID.startsWith("l_")) {
      queryString = "?linked=true";
    } else {
      queryString = "";
    }
    API.patch(
      "cayco",
      `/${packageDetails.UUID}/${packageDetails.trackNo}${queryString}`,
      {
        body: {
          invNo: invNo,
        },
      }
    ).then((res) => {
      if (res.invNo) {
        setPackageDetails({ ...packageDetails, invNo: res.invNo });
      }
    });
  }*/

  return (
    <Modal
      contentClassName="modal-width"
      dialogClassName="modal-width"
      show={show}
      onHide={handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title className="align-items-center d-flex">
          Invoice: {packageDetails.invNo}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="mx-1" md="6">
            <h5>Items ({packageDetails.info && packageDetails.info.length}):</h5>
            {packageDetails.info && (
              <>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center">Name</th>
                      <th className="text-center">Code</th>

                      <th width="100" className="text-center">
                        Qty
                      </th>
                      <th width="50" className="text-center">
                        Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {packageDetails.info &&
                      packageDetails.info.map((item, i) => (
                        <tr key={i}>
                          <td className="text-center align-middle">
                            {item.showSave && item.hasChanged !== item.Code ? (
                              <Button
                                className="font-weight-bold"
                                size="sm"
                                variant="success"
                                onClick={() => {
                                  const prevItems = [...packageDetails.info];
                                  const updatedItem = {
                                    ...prevItems[i],
                                    submitted: true,
                                  };
                                  prevItems[i] = updatedItem;
                                  setPackageDetails({
                                    ...packageDetails,
                                    info: prevItems,
                                  });
                                  updateCodes(i);
                                }}
                                disabled={
                                  item.Code.toString().length !== 8 ||
                                  item.submitted
                                }
                              >
                                {item.submitted ? (
                                  <Spinner
                                    size="sm"
                                    animation="border"
                                    role="status"
                                  >
                                    <span className="sr-only p-2">
                                      Loading...
                                    </span>
                                  </Spinner>
                                ) : (
                                  "✔"
                                )}
                              </Button>
                            ) : (
                              <Button
                                className="font-weight-bold"
                                size="sm"
                                variant="danger"
                                onClick={() => deleteItem(i)}
                              >
                                X
                              </Button>
                            )}
                          </td>

                          <td className="align-middle">
                            {item.Name.length >= 50
                              ? item.Name.substring(0, 50) + "..."
                              : item.Name}
                          </td>
                          <td className="align-middle text-center">
                            <FormControl
                              className="text-center"
                              onChange={(e) => {
                                if (!item.hasChanged) {
                                  item.hasChanged = item.Code;
                                }
                                const prevItems = [...packageDetails.info];
                                const updatedItem = {
                                  ...prevItems[i],
                                  Code: parseInt(e.target.value),
                                  showSave: true,
                                };
                                prevItems[i] = updatedItem;
                                setPackageDetails({
                                  ...packageDetails,
                                  info: prevItems,
                                });
                              }}
                              value={item.Code || ""}
                            />
                          </td>
                          <td className="align-middle text-center">
                            {item.Quantity}
                          </td>
                          <td className="p-0 align-middle text-center">
                            <Button
                              onClick={() => console.log("test")}
                              className="w-100 border"
                              variant="light"
                            >
                              ${item.Price.toFixed(2)}
                            </Button>
                          </td>
                        </tr>
                      ))}
                    <>
                      <tr style={{ verticalAlign: "middle" }}>
                        <td className="align-middle text-center">
                          <Button
                            variant="primary"
                            className="font-weight-bold"
                            style={{ fontSize: 25 }}
                            size="sm"
                            onClick={addNewItem}
                            disabled={saveItemDisabled}
                          >
                            {saveItemSpinnerShow ? (
                              <Spinner
                                size="sm"
                                animation="border"
                                role="status"
                              >
                                <span className="sr-only p-2">Loading...</span>
                              </Spinner>
                            ) : (
                              "+"
                            )}
                          </Button>
                        </td>
                        <td className="align-middle">
                          <FormControl
                            placeholder="Name"
                            onChange={(e) => {
                              setAddItem({
                                ...addItem,
                                Name: e.target.value,
                              });
                              setSaveItemDisabled(false);
                            }}
                            value={addItem.Name || ""}
                          />
                        </td>
                        <td className="align-middle">
                          <FormControl
                            className="text-center"
                            placeholder="Code"
                            onChange={(e) => {
                              setAddItem({
                                ...addItem,
                                Code: e.target.value,
                              });
                              setSaveItemDisabled(false);
                            }}
                            value={addItem.Code || ""}
                          />
                        </td>
                        <td className="align-middle">
                          <FormControl
                            className="text-center"
                            placeholder="Qty"
                            onChange={(e) => {
                              setAddItem({
                                ...addItem,
                                Quantity: e.target.value,
                              });
                              setSaveItemDisabled(false);
                            }}
                            value={addItem.Quantity || ""}
                          />
                        </td>

                        <td className="align-middle">
                          <FormControl
                            className="text-center"
                            placeholder="$"
                            onChange={(e) => {
                              setAddItem({
                                ...addItem,
                                Price: e.target.value,
                              });
                              setSaveItemDisabled(false);
                            }}
                            value={addItem.Price || ""}
                          />
                        </td>
                      </tr>
                    </>
                  </tbody>
                </Table>
                {packageDetails.discounts > 0 && (
                  <h5>
                    Discounts: -${packageDetails.discounts.toFixed(2)} USD
                  </h5>
                )}
                {packageDetails.totalPreTax && (
                  <h5>
                    Total Dutiable Value = $
                    {packageDetails.totalPreTax &&
                      packageDetails.totalPreTax.toFixed(2)}{" "}
                    USD
                  </h5>
                )}{" "}
              </>
            )}
          </Col>
          <Col className="mx-0 px-0" md="4" style={{ minHeight: "60vh" }}>
            <Document
              onLoadSuccess={onDocumentLoadSuccess}
              className="border"
              file={fileURL}
            >
              <Page
                scale={1}
                canvasRef={pageRef}
                onClick={(e) => getClick(e, pageNumber)}
                onLoadSuccess={removeTextLayerOffset}
                pageNumber={pageNumber}
              />
            </Document>
            <div>
              <p>
                Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
              </p>
              <button
                type="button"
                disabled={pageNumber <= 1}
                onClick={previousPage}
              >
                Previous
              </button>
              <button
                type="button"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
              >
                Next
              </button>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
