import { useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";

import UploadFile from "./uploadFile";
import { API } from "@aws-amplify/api";
export default function AddPackage() {
  const [showAddPackageSpinner, setShowAddPackageSpinner] = useState(false);
  const [addNewPackageDetails, setAddNewPackageDetails] = useState({});
  const childState = useRef(null);

  function addNewPackage(event) {
    event.preventDefault();
    setShowAddPackageSpinner(true);
    API.post(
      `/admin/package/${addNewPackageDetails.trackNo.toUpperCase()}`,
      addNewPackageDetails
    )
      .then((res) => {
        setShowAddPackageSpinner(false);
      })
      .catch((err) => {
        setShowAddPackageSpinner(false);
      });
  }

  return (
    <div className="bg-light border border-dark rounded-lg text-dark p-2 text-left">
      <Form onSubmit={addNewPackage}>
        <InputGroup>
          <Form.Control
            type="text"
            required
            placeholder="Tracking Number (Required)"
            onChange={(e) => {
              setAddNewPackageDetails({
                ...addNewPackageDetails,
                trackNo: e.target.value,
              });
            }}
          />
          <InputGroup.Append>
            <InputGroup.Text>Track #</InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        <InputGroup>
          <Form.Control
            type="text"
            placeholder="D/R Number (Required)"
            required
            onChange={(e) => {
              setAddNewPackageDetails({
                ...addNewPackageDetails,
                drNo: e.target.value,
              });
            }}
          />
          <InputGroup.Append>
            <InputGroup.Text>&nbsp;&nbsp;D/R #</InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        <Form.Row>
          <Col md="4">
            <InputGroup>
              <Form.Control
                required
                placeholder="Actual Weight (Required)"
                onChange={(e) => {
                  setAddNewPackageDetails({
                    ...addNewPackageDetails,
                    weight: parseFloat(e.target.value),
                  });
                }}
              />
              <InputGroup.Append>
                <InputGroup.Text>lbs</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col md="4">
            <InputGroup>
              <Form.Control
                required
                placeholder="CuFt (Or Dims)"
                onChange={(e) => {
                  setAddNewPackageDetails({
                    ...addNewPackageDetails,
                    cuFt: parseFloat(e.target.value),
                  });
                }}
              />
              <InputGroup.Append>
                <InputGroup.Text>CuFt</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Form.Row>
        <Form.Row className="mb-2">
          <Col md="4">
            <InputGroup className="mr-sm-2">
              <Form.Control
                required
                placeholder="Length (In; or CuFt)"
                onChange={(e) => {
                  setAddNewPackageDetails({
                    ...addNewPackageDetails,
                    dims: {
                      ...addNewPackageDetails.dims,
                      length: parseFloat(e.target.value),
                    },
                  });
                }}
              />
              <InputGroup.Append>
                <InputGroup.Text>&nbsp;L&nbsp;</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col md="4">
            <InputGroup className="mr-sm-2">
              <Form.Control
                required
                placeholder="Width (In; or CuFt)"
                onChange={(e) => {
                  setAddNewPackageDetails({
                    ...addNewPackageDetails,
                    dims: {
                      ...addNewPackageDetails.dims,
                      width: parseFloat(e.target.value),
                    },
                  });
                }}
              />
              <InputGroup.Append>
                <InputGroup.Text>W</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col md="4">
            <InputGroup className="mr-sm-2">
              <Form.Control
                required
                placeholder="Height (In; or CuFt)"
                onChange={(e) => {
                  setAddNewPackageDetails({
                    ...addNewPackageDetails,
                    dims: {
                      ...addNewPackageDetails.dims,
                      height: parseFloat(e.target.value),
                    },
                  });
                }}
              />
              <InputGroup.Append>
                <InputGroup.Text>&nbsp;H</InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </Form.Row>

        <UploadFile
          dialogName="Select an invoice (PDF; 10MB Max)"
          childRef={childState}
        />

        <div className="text-center">
          <Button
            variant="success"
            className="mt-2 d-flex align-items-center text-center font-weight-bold"
            type="submit"
            disabled={showAddPackageSpinner}
          >
            Add Package
            {showAddPackageSpinner && (
              <Spinner
                className="ml-2"
                size="sm"
                animation="border"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </Spinner>
            )}
          </Button>
        </div>
      </Form>
    </div>
  );
}
