import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useFormFields } from "../libs/hooks";
import { Auth } from "aws-amplify";
export default function Signup() {
  const [fields, handleFieldChange] = useFormFields({
    email: "",
    password: "",
    givenName: "",
    familyName: "",
    phoneNumber: "",
    confirmPassword: "",
    confirmationCode: "",
  });
  const [newUser, setNewUser] = useState(null);

  function validateForm() {
    return (
      fields.email.length > 0 &&
      fields.password.length > 0 &&
      fields.password === fields.confirmPassword
    );
  }

  async function handleSubmit(event) {
    event.preventDefault();
    try {
      const newUser = await Auth.signUp({
        username: fields.email,
        password: fields.password,
        attributes: {
          email: fields.email,
          phone_number: fields.phoneNumber,
          given_name: fields.givenName,
          family_name: fields.familyName,
        },
      });
      setNewUser(newUser);
    } catch (e) {}
  }

  function renderForm() {
    return (
      <>
        <h4 className="mt-2">Sign Up</h4>
        <Form className="text-left" onSubmit={handleSubmit}>
          <Form.Group controlId="givenName" size="lg">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              autoFocus
              type="name"
              value={fields.givenName}
              placeholder="First Name"
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group controlId="familyName" size="lg">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              autoFocus
              type="name"
              value={fields.familyName}
              placeholder="Last Name"
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group controlId="phoneNumber" size="lg">
            <Form.Label>Phone Number</Form.Label>
            <Form.Control
              autoFocus
              type="phone"
              value={fields.phoneNumber}
              placeholder="Phone Number (+13451234567)"
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group controlId="email" size="lg">
            <Form.Label>Email</Form.Label>
            <Form.Control
              autoFocus
              type="email"
              value={fields.email}
              placeholder="Email"
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group controlId="password" size="lg">
            <Form.Label>Password</Form.Label>
            <Form.Control
              type="password"
              value={fields.password}
              placeholder="Password (8 characters, Upper & Lowercase, Number)"
              onChange={handleFieldChange}
            />
          </Form.Group>
          <Form.Group controlId="confirmPassword" size="lg">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Confirm Password"
              onChange={handleFieldChange}
              value={fields.confirmPassword}
            />
          </Form.Group>
          <Button
            block
            size="lg"
            type="submit"
            variant="success"
            disabled={!validateForm()}
          >
            Sign Up
          </Button>
        </Form>
      </>
    );
  }
  return (
    <div className="Signup">{newUser === null ? renderForm() : false}</div>
  );
}
