import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Amplify } from "aws-amplify";
import config from "./config";

Amplify.configure({
  Auth: {
    identityPoolId: "us-east-1:e56fc714-789a-4f2d-993f-ad791125f4d8",
    mandatorySignIn: false,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  API: {
    endpoints: [{ name: "cayco", endpoint: "https://api.cayco.ky" }],
  },
  Storage: {
    AWSS3: {
      bucket: "cayco",
      region: "us-east-1",
    },
    customPrefix: {
      public: 'invoice/',
      protected: 'cargoReports/'
    }
  },
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
