import { useState, useEffect, useContext } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Fade from "react-bootstrap/Fade";
import Form from "react-bootstrap/Form";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import PackageDetails from "../components/packageDetails";
import UploadFile from "../components/uploadFile";
import LinkedShipment from "../components/linkedShipment";

import { API } from "@aws-amplify/api";
import { Storage } from "@aws-amplify/storage";

import * as CarrierRegex from "../libs/carrierRegex";
import { AppContext } from "../libs/context";
import List from "../components/list";

export default function Tracking() {
  const [fadeShow, setFadeShow] = useState(true);
  const [packageDetails, setPackageDetails] = useState({});
  const [showSpinner, setShowSpinner] = useState(false);
  const [
    showPredictiveCarrierConfirmation,
    setShowPredictiveCarrierConfirmation,
  ] = useState(false);
  const [linkedArray, setLinkedArray] = useState([undefined]);
  const [disabled, setDisabled] = useState(true);
  const [showTracking, setShowTracking] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [activePackages, setActivePackages] = useState([]);
  const [activePackagesLoading, setActivePackagesLoading] = useState(true);
  const [trackNo, setTrackNo] = useState("");
  const [file, setFile] = useState(null);
  const context = useContext(AppContext);
  //make global so background calls will stop CHANGE!!
  let stopRetry = 0;

  useEffect(() => {
    getActivePackages();
  }, []);

  async function getActivePackages() {
    let concatted = [];
    await API.get("cayco", `/${context.UUID}?linked=true`).then((linkedRes) => {
      concatted = linkedRes;
      setActivePackages(concatted);
      setActivePackagesLoading(false);
    });
    API.get("cayco", `/${context.UUID}?`).then((pkgRes) => {
      pkgRes.sort((a) => (a.parent ? 1 : -1));
      concatted = concatted.concat(pkgRes);
      setActivePackages(concatted);
    });
  }

  //add package tracking no, UUID, and carrier to dB
  async function postPackage() {
    const sanitizedtrackingNo = await linkedArray[0].trackNo
      .replace(/\s/g, "")
      .toUpperCase()
      .trim();
    if (linkedArray.length === 1) {
      API.post(
        "cayco",
        `/${context.UUID}/${sanitizedtrackingNo}?carrier=${linkedArray[0].carrier}`,
        { body: {} }
      )
        .then((pkgRes) => {
          setTrackNo(linkedArray[0].trackNo);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      API.post("cayco", `/${context.UUID}/link`, {
        body: linkedArray,
      })
        .then((res) => {
          API.get("cayco", `/${context.UUID}?linked=true&active=true`)
            .then((queryRes) => {
              setTrackNo(queryRes[0].activeSince);
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  // Business rules to predictively identify tracking numbered entered to carrier
  async function trackPackage(event) {
    event.preventDefault();
    const sanitizedtrackingNo = await linkedArray[0].trackNo
      .replace(/\s/g, "")
      .toUpperCase()
      .trim();
    setDisabled(true);
    API.get("cayco", `/${context.UUID}/${sanitizedtrackingNo}`).then((res) => {
      if (res.info) {
        //if tracking no. has already been processed, show results
        setFadeShow(false);
        setTimeout(() => setShowTracking(true), 300);
        setPackageDetails(res);
      } else if (res.carrier && !res.jobId) {
        //if carrier is returned but no invoice, direct to invoice uplaod
        confirmCarrier();
      } else {
        setShowPredictiveCarrierConfirmation(true);
      }
    });
  }

  function findCarrier(e, i) {
    const usps = CarrierRegex.usps_pattern.join("|");
    const ups = CarrierRegex.ups_pattern.join("|");
    const fedex = CarrierRegex.fedex_pattern.join("|");
    let predictiveCarrier;
    e = e.toUpperCase();
    if (e.startsWith("TBA")) {
      predictiveCarrier = "Amazon";
    } else if (e.match(usps)) {
      predictiveCarrier = "USPS";
    } else if (e.match(ups)) {
      predictiveCarrier = "UPS";
    } else if (e.match(fedex)) {
      predictiveCarrier = "FedEx";
    } else {
      predictiveCarrier = "Select a carrier";
    }
    let newArr = [...linkedArray];
    newArr[i] = { ...linkedArray[i], trackNo: e, carrier: predictiveCarrier };
    setLinkedArray(newArr);
  }

  function confirmCarrier() {
    if (showPredictiveCarrierConfirmation === true) {
      postPackage();
    }
    setShowSpinner(false);
    setFadeShow(false);
    setDisabled(false);
    setShowPredictiveCarrierConfirmation(false);
    setTimeout(() => setShowInvoice(true), 300);
  }

  async function getDetails(type) {
    let i = 0;
    function timeout(delay) {
      return new Promise((res) => setTimeout(res, delay));
    }
    await timeout(10000);
    API.get("cayco", `/${context.UUID}/${trackNo}${type}`).then((res) => {
      if (stopRetry < 20) {
        if (res && res.info) {
          setShowDetails(false);
          setPackageDetails(res);
          setShowTracking(true);
          setTimeout(() => setShowTracking(true), 400);
          stopRetry = 0;
        } else {
          stopRetry++;
          getDetails(type);
        }
      } else {
        stopRetry = 0;
      }
    });
  }

  async function uploadInvoice(event) {
    event.preventDefault();
    setShowSpinner(true);
    setDisabled(true);
    const pkgPrefix = linkedArray.length === 1 ? "pkg" : "l";
    const afterScore =
      linkedArray.length === 1 ? linkedArray[0].trackNo : trackNo;
    Storage.put(`${context.UUID}/${pkgPrefix}_${afterScore}.pdf`, file, {
      contentType: file.type,
    })
      .then((res) => {
        const type = linkedArray.length === 1 ? "" : "?linked=true";
        setShowSpinner(false);
        setDisabled(false);
        setShowInvoice(false);
        setTimeout(() => {
          setShowDetails(true);
          setShowSpinner(true);
        }, 300);
        getDetails(type);
      })
      .catch((err) => {
        console.log(err);
        setShowSpinner(false);
        setDisabled(false);
      });
  }

  // return to home
  function trackAnother() {
    setLinkedArray([undefined]);
    getActivePackages();
    setShowTracking(false);
    setShowSpinner(false);
    setShowDetails(false);
    setTimeout(() => setFadeShow(true), 300);
  }
  //merge arrays
  function addLinkedPackage() {
    const arrConcat = linkedArray.concat({
      trackNo: undefined,
      carrier: "Select a carrier",
    });
    setLinkedArray(arrConcat);
  }

  function handleCarrierArrayChange(e, i) {
    let newArr = [...linkedArray];
    newArr[i] = { ...linkedArray[i], carrier: e };
    setLinkedArray(newArr);
  }

  return (
    <div className="mt-2">
      {/*Tracking No. & UUID submit */}
      <Fade
        in={fadeShow}
        appear={true}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <>
          <Form onSubmit={trackPackage}>
            {linkedArray.map((pkg, i) => (
              <InputGroup key={i}>
                <Form.Control
                  placeholder="Enter Tracking Number"
                  onChange={(e) => {
                    findCarrier(e.target.value, i);
                    setDisabled(false);
                  }}
                  name="trackingNo"
                />
                <InputGroup.Append>
                  {showPredictiveCarrierConfirmation === true &&
                  linkedArray.length > 0 ? (
                    <DropdownButton
                      name="predictiveCarrier"
                      variant="info"
                      title={pkg.carrier}
                      onSelect={(e) => handleCarrierArrayChange(e, i)}
                    >
                      <Dropdown.Item eventKey="Amazon">
                        Carrier: Amazon
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="FedEx">
                        Carrier: FedEx
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="UPS">Carrier: UPS</Dropdown.Item>
                      <Dropdown.Item eventKey="USPS">
                        Carrier: USPS
                      </Dropdown.Item>
                    </DropdownButton>
                  ) : (
                    <Button
                      className="d-flex align-items-center font-weight-bold"
                      variant="success"
                      id="trackingNo"
                      onClick={trackPackage}
                      disabled={disabled}
                    >
                      Track
                    </Button>
                  )}
                </InputGroup.Append>
              </InputGroup>
            ))}
          </Form>
          {/*Carrier Selection and Confirmation*/}
          {showPredictiveCarrierConfirmation === true && (
            <Row className="mt-3">
              <Col className="mb-2" md="6">
                <Button
                  onClick={addLinkedPackage}
                  variant="secondary"
                  type="submit"
                  className="ml-2 font-weight-bold"
                >
                  Add Another Tracking Number to Your Invoice
                </Button>
              </Col>
              <Col className="mb-2" md="6">
                <Button
                  onClick={confirmCarrier}
                  variant="success"
                  type="submit"
                  className="ml-2 font-weight-bold"
                >
                  Confirm Packages and Carriers
                </Button>
              </Col>
            </Row>
          )}
          {/*Show Active Packages */}
          <div className="text-left">
            {!activePackagesLoading && (
              <h4 className="mt-2">Active Packages & Invoices</h4>
            )}
            {activePackagesLoading ? (
              <div className="text-center mt-3">
                <Spinner variant="light" animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <List activePackages={activePackages} />
              </>
            )}
          </div>
        </>
      </Fade>
      {/*Upload Invoice */}
      <Fade in={showInvoice} unmountOnExit={true} mountOnEnter={true}>
        <Form>
          <Form.Group>
            <h4 className="text-left mb-2">Upload Invoice</h4>
            <UploadFile
              dialogName="Select an invoice (PDF; 10MB Max)"
              setFile={setFile}
            />
          </Form.Group>
          <Button
            onClick={uploadInvoice}
            disabled={file === null || showSpinner}
            variant="success"
            size="lg"
            type="submit"
            className="font-weight-bold mb-2"
          >
            <div className="d-flex align-items-center">
              Upload
              {showSpinner === true && (
                <Spinner
                  size="sm"
                  className="ml-2"
                  animation="border"
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </Spinner>
              )}
            </div>
          </Button>
        </Form>
      </Fade>
      {/* Waiting for Textract */}
      <Fade in={showDetails} unmountOnExit={true} mountOnEnter={true}>
        <div>
          <h4>Please wait while we process your invoice. . .</h4>
          <p>(This could take a couple minutes)</p>
          <Spinner className="ml-2 my-3" animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>

          <h4 className="mt-3">Or, while you wait: </h4>
          <Button
            onClick={trackAnother}
            variant="info"
            size="lg"
            className="font-weight-bold"
            block
          >
            Track Another Package
          </Button>
        </div>
      </Fade>
      {/* Package details page */}
      <Fade in={showTracking} unmountOnExit={true} mountOnEnter={true}>
        <div>
          {showTracking && packageDetails.linked && (
            <LinkedShipment initShipmentDetails={packageDetails} />
          )}
          {showTracking && packageDetails.invType && !packageDetails.linked && (
            <PackageDetails initPackageDetails={packageDetails} />
          )}
          <Button
            onClick={trackAnother}
            variant="info"
            size="lg"
            className="my-3 font-weight-bold"
            block
          >
            Track Another Package
          </Button>
        </div>
      </Fade>
      <footer className="py-2 text-center rounded rounded-lg mt-3 bg-light">
        <span className="text-body">
          Made with ❤️ by Adrian Stone. Copyright 2021.
        </span>
      </footer>
    </div>
  );
}
