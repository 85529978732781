import { useContext, useState } from "react";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import EditInvoice from "./editInvoice";
import uspsLogo from "../assets/logos/usps.png";
import upsLogo from "../assets/logos/ups.png";
import fedexLogo from "../assets/logos/fdx.png";
import amzLogo from "../assets/logos/amz.png";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { AppContext } from "../libs/context";
import { API } from "@aws-amplify/api";

export default function LinkedShipment({
  shipmentDetails,
  setSelectedPackageDetails,
}) {
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const handleClose = () => setShow(false);
  const context = useContext(AppContext);

  function deletePkg(pkg) {
    let queryString;
    if (shipmentDetails.UUID.startsWith("l_")) {
      queryString = "?linked=true";
    } else {
      queryString = "";
    }
    API.patch(
      "cayco",
      `/${context.UUID}/${shipmentDetails.trackNo}${queryString}`,
      {
        body: {
          deletePkg: pkg,
        },
      }
    ).then((res) => {
      console.log(res);
      if (res.linked) {
        setSelectedPackageDetails({ ...shipmentDetails, linked: res.linked });
      } else {
        setSelectedPackageDetails({ ...shipmentDetails, linked: [] });
      }
    });
  }

  function assignTrackNo(pkg) {
    const result = shipmentDetails.linkedPkgDetails.filter(
      (pkgTrackNo) => pkgTrackNo.trackNo === pkg
    );
    return result[0];
  }
  return (
    <>
      <EditInvoice
        show={show}
        handleClose={handleClose}
        packageDetails={shipmentDetails}
        setPackageDetails={setSelectedPackageDetails}
      />
      <div className="bg-light border border-dark rounded-lg text-dark p-2 text-left">
        <div
          style={{ backgroundColor: "#ebeced" }}
          className="border rounded-lg m-2 p-2"
        >
          <h4>Your shipment is on its way!</h4>
        </div>
        <h5 className="d-flex align-content-end align-items-center">
          Packages:
          <div className="ml-auto">
            {showEdit && (
              <>
                <Button
                  variant="success"
                  onClick={() => {
                    setShowEdit(!showEdit);
                  }}
                  className="mr-2 font-weight-bold"
                >
                  Add Package
                </Button>
                <Button className="mr-2 font-weight-bold" variant="danger">
                  Delete Shipment
                </Button>
              </>
            )}
            <Button
              variant="info"
              onClick={() => {
                setShowEdit(!showEdit);
              }}
              className="ml-auto font-weight-bold"
            >
              {showEdit ? "Done Editing" : "Edit"}
            </Button>
          </div>
        </h5>
        <Row>
          <Col md="6">
            <Table className="mb-0" responsive striped bordered hover>
              <tbody>
                {shipmentDetails.linked.map((pkg, i) => (
                  <tr key={i}>
                    <td className="d-flex align-items-center">
                      <Button
                        style={{
                          color: "#17a2b8",
                        }}
                        className="font-weight-bold"
                        variant="link"
                      >
                        {pkg}
                        {assignTrackNo(pkg).carrier === "USPS" && (
                          <img
                            className="ml-2"
                            height="35em"
                            src={uspsLogo}
                            alt="Logo"
                          />
                        )}
                        {assignTrackNo(pkg).carrier === "UPS" && (
                          <img
                            className="ml-2"
                            height="25em"
                            src={upsLogo}
                            alt="Logo"
                          />
                        )}
                        {assignTrackNo(pkg).carrier === "FedEx" && (
                          <img
                            className="ml-2"
                            height="25em"
                            src={fedexLogo}
                            alt="Logo"
                          />
                        )}
                        {assignTrackNo(pkg).carrier === "Amazon" && (
                          <img
                            className="ml-2"
                            height="15em"
                            src={amzLogo}
                            alt="Logo"
                          />
                        )}
                      </Button>
                      <div className="ml-auto font-weight-bold">
                        {assignTrackNo(pkg).cuFt &&
                          assignTrackNo(pkg).cuFt + " CuFt; "}
                        {assignTrackNo(pkg).weight &&
                          assignTrackNo(pkg).weight + " lbs"}
                        {!assignTrackNo(pkg).weight &&
                          !assignTrackNo(pkg).weight &&
                          "Not Recieved"}
                        {showEdit && (
                          <Button
                            variant="danger"
                            onClick={() => deletePkg(pkg)}
                            className="ml-2 font-weight-bold"
                          >
                            X
                          </Button>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
        <h5 className="d-flex my-0 align-items-center">
          <span>Invoice Number:</span>
          <Button
            onClick={(e) => setShow(true)}
            className="mb-0 font-weight-bold"
            size="lg"
            variant="link"
          >
            {shipmentDetails.invNo}
          </Button>
        </h5>
        {shipmentDetails.discounts > 0 && (
          <h5>Discounts: -${shipmentDetails.discounts.toFixed(2)} USD</h5>
        )}
        {shipmentDetails.totalPreTax && (
          <h5>
            Total Dutiable Value: $
            {shipmentDetails.totalPreTax &&
              shipmentDetails.totalPreTax.toFixed(2)}{" "}
            USD
          </h5>
        )}
        <Table responsive striped bordered hover>
          <thead>
            <tr>
              <th>Quantity</th>
              <th>Item Name</th>
              <th>Price</th>
            </tr>
          </thead>
          <tbody>
            {shipmentDetails.info &&
              shipmentDetails.info.map((item, i) => (
                <tr key={i}>
                  <td className="text-center">{item.Quantity}</td>
                  <td>{item.Name}</td>
                  <td>${item.Price.toFixed(2)}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
    </>
  );
}
