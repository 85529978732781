import Form from "react-bootstrap/Form";
import { useState } from "react";
export default function UploadFile({ dialogName, setFile }) {
  const [browserLabel, setBrowserLabel] = useState(dialogName);
  function selectFile(event) {
    const targetFile = event.target.files[0];
    setFile(targetFile);
    setBrowserLabel(targetFile.name);
  }

  return (
    <>
      <Form.File
        id="uploadFile"
        label={browserLabel}
        onChange={(e) => selectFile(e)}
        custom
      />
    </>
  );
}
