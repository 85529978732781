import "bootstrap/dist/css/bootstrap.min.css";

import "./App.css";
import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Routes from "./Routes";
import { Link, useHistory } from "react-router-dom";
import { AppContext } from "./libs/context";
import { Auth } from "@aws-amplify/auth";

export default function App() {
  const history = useHistory();
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [UUID, setUUID] = useState("");

  useEffect(() => {
    onLoad();
  }, []);

  useEffect(() => {
    if (isAuthenticated === true) {
      checkId();
    }
  });

  async function checkId() {
    const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
    const hasId = currentAuthenticatedUser.attributes["custom:idp"];
    if (!hasId) {
      const currentId = (await Auth.currentCredentials()).identityId;
      Auth.updateUserAttributes(currentAuthenticatedUser, {
        "custom:idp": currentId,
      });
    }
  }

  async function onLoad() {
    try {
      await Auth.currentSession();
      const id = (await Auth.currentAuthenticatedUser()).attributes[
        "custom:idp"
      ];
      setUUID(id);
      userHasAuthenticated(true);
    } catch (e) {
      if (e !== "No current user") {
        alert(e);
      }
    }
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();
    userHasAuthenticated(false);
    setUUID("");
    history.push("/login");
  }

  return (
    <div className="main">
      <Navbar bg="primary" variant="dark">
        <Navbar.Brand className="font-weight-bold" as={Link} to="/">
          Cayco
        </Navbar.Brand>
        {isAuthenticated ? (
          <>
            <Nav className="mr-auto">
              <Nav.Link as={Link} to="/admin">
                Admin
              </Nav.Link>
            </Nav>
            <Button
              className="font-weight-bold"
              onClick={handleLogout}
              variant="warning"
            >
              Log Out
            </Button>
          </>
        ) : (
          <>
            <Nav.Link className="text-white" as={Link} to="/login">
              Login
            </Nav.Link>
            <Nav.Link className="text-white" as={Link} to="/signup">
              Sign Up
            </Nav.Link>
          </>
        )}
      </Navbar>
      <Container>
        {!isAuthenticating && (
          <AppContext.Provider
            value={{ setUUID, UUID, isAuthenticated, userHasAuthenticated }}
          >
            <Routes />
          </AppContext.Provider>
        )}
      </Container>
    </div>
  );
}
