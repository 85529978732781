import { useState } from "react";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import UploadFile from "./uploadFile";
import { Storage } from "@aws-amplify/storage";

export default function UploadCargoReport() {
  const [showSpinner, setShowSpinner] = useState(false);
  const [file, setFile] = useState(null);
  function uploadCargoReport(e) {
      e.preventDefault();
      setShowSpinner(true);
      Storage.put("cargoReport.csv", file, {
        level: "protected",
        contentType: file.type,
      })
        .then((res) => {
          setShowSpinner(false);
        })
        .catch((err) => {
          setShowSpinner(false);
        });
    }
  return (
    <div className="bg-light border border-dark rounded-lg text-dark p-2 text-center">
      <UploadFile
        dialogName={"Select a CSV File (10MB Max)"}
        setFile={setFile}
      />
      <Button
        onClick={uploadCargoReport}
        variant="success"
        type="submit"
        className="font-weight-bold mt-2"
        disabled={showSpinner || file == null}
      >
        <div className="d-flex align-items-center ">
          Upload Cargo Report
          {showSpinner === true && (
            <Spinner
              size="sm"
              className="ml-2"
              animation="border"
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </Spinner>
          )}
        </div>
      </Button>
    </div>
  );
}
