import { useEffect, useState } from "react";
import AddPackage from "../components/addPackage";
import UploadCargoReport from "../components/uploadCargoReport";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import { API } from "@aws-amplify/api";
import List from "../components/list";
export default function Admin() {
  const [activePackages, setActivePackages] = useState([]);
  const [showEdit, setShowEdit] = useState(false);
  const [selected, setSelected] = useState([]);
  useEffect(() => {
    getActivePackages();
  }, []);

  async function getActivePackages() {
    API.get("cayco", "/admin/package").then((res) => {
      res.sort((a, b) => (a.activeSince < b.activeSince ? 1 : -1));
      res.sort((a) => (a.parent ? 1 : -1));
      setActivePackages(res);
    });
  }

  function deleteItems() {
    //  API.delete("cayco", "/delete/")
  }

  function xmlGen() {
    API.post("cayco", "/admin/xml", { body: { selected } })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleCheckboxChange(checkBoxSelected, checked) {
    if (checked) {
      setSelected(selected.concat(checkBoxSelected));
    } else {
      const removed = selected.filter(
        (item) => item.trackNo !== checkBoxSelected.trackNo
      );
      setSelected(removed);
    }
  }
  // MAKE, ARE YOU SURE MODAL after all admin actions?
  return (
    <div className="text-left mt-2">
      <h3>Upload Cargo Report</h3>
      <UploadCargoReport />
      <h3 className="mt-2">Add Package Manually</h3>
      <AddPackage />
      <h3 className="my-2">
        All Active Packages & Invoices{" "}
        <Button
          variant="info"
          onClick={() => setShowEdit(!showEdit)}
          className="font-weight-bold border float-right"
        >
          Bulk Edit
        </Button>
      </h3>
      <FormControl className="mb-2" placeholder="Customer Name"></FormControl>
      {showEdit && (
        <div className="mb-2">
          <Button
            variant="danger"
            className="border"
            onClick={() => console.log(selected)}
          >
            Delete
          </Button>
          <Button
            variant="secondary"
            className="ml-2 border"
            onClick={() => console.log(selected)}
          >
            Create Merged PDF
          </Button>
          <Button variant="success" className="ml-2 border" onClick={xmlGen}>
            Generate COLS XML
          </Button>
        </div>
      )}
      <List
        activePackages={activePackages}
        showCheckBox={showEdit}
        handleCheckboxChange={handleCheckboxChange}
        level={0}
      />
    </div>
  );
}
